<template>
  <div>
    <Frame title="Razones sociales" :loading="loading">
      <Grid
        :data="data"
        :columns="columns"
        :actions="actions"
        addText="Agregar razón social"
        :showAdd="$can('AddCompany', 'STM')"
        @addNew="addNew"
        @reloadData="getCompanies"
      />
    </Frame>
    <Modal :active.sync="showModal" class="modal-with-overflow" :width="960">
      <CompanyForm @legalNameInfo="() => (this.showModalLegalName = true)" />
    </Modal>
    <Modal :active.sync="showModalEdit" class="modal-with-overflow" :width="960">
      <CompanyEdit
        @legalNameInfo="() => (this.showModalLegalName = true)"
        :companyToView="companyToView"
        :readonly="readonly"
      />
    </Modal>
    <Modal :active.sync="showModalLegalName" :width="860">
      <BlobImage src="legal-name-info.png" width="90%" />
    </Modal>
  </div>
</template>

<script>
import ApiClient from '../../../utils/ApiClient';
import CompanyForm from './CompanyForm.vue';
import CompanyEdit from './CompanyEdit.vue';

export default {
  name: 'Companies',
  components: {
    CompanyForm,
    CompanyEdit,
  },
  data() {
    return {
      data: [],
      actions: [
        {
          text: 'Ver',
          callback: this.onView,
        },
      ],
      showModal: false,
      showModalEdit: false,
      showModalLegalName: false,
      loading: false,
      companyToView: null,
      columns: [
        {
          title: 'Código',
          field: 'CompanyCode',
          searchable: true,
        },
        {
          title: 'Razón social',
          field: 'LegalName',
          searchable: true,
        },
        {
          title: 'RFC',
          field: 'RFC',
          searchable: true,
        },
        {
          title: 'Regimen fiscal',
          field: 'RegimenFiscalDescription',
        },
        {
          title: 'Código postal',
          field: 'ZipCode',
        },
      ],
      readonly: false,
    };
  },
  mounted() {
    if (this.$can('EditCompany', 'STM'))
      this.actions.push({
        text: 'Editar',
        callback: this.onEdit,
      });

    if (this.$can('DeleteCompany', 'STM'))
      this.actions.push({
        text: 'Eliminar',
        callback: this.deleteCompany,
      });
    this.getCompanies();
  },
  methods: {
    getCompanies() {
      this.loading = true;
      const apiClient = new ApiClient('stamp/org/companies', 'stamp');
      apiClient.Get('?query=a').then((data) => {
        this.data = data;
        this.loading = false;
      });
    },
    addNew() {
      this.showModal = true;
    },
    deleteCompany(item) {
      this.deleteItem(
        'company',
        item.PKCompany,
        '¿Desea eliminar la razón social?',
        'Al eliminar la razón social, no podra ser utilizada.',
        'stamp'
      );
    },

    onView(item) {
      this.readonly = true;
      this.companyToView = item;
      this.showModalEdit = true;
    },
    onEdit(item) {
      this.readonly = false;
      this.companyToView = item;
      this.showModalEdit = true;
    },
  },
};
</script>

<style lang="scss">
.modal-with-overflow {
  .pw_modal__modal-content {
    overflow-y: scroll;
  }
}
</style>
