<template>
  <div>
    <div v-if="!isSigning">
      <p>
        Selecciona el PAC para firmar el manifiesto o consultar contratos o avisos de privacidad.
      </p>
      <br />
      <Layout>
        <Column v-for="(pac, index) in pacs" :key="index" size="6" align="center">
          <div class="pwCard">
            {{ pac.pacLegalName }}
            <span
              @click="sign(pac)"
              class="PwSign"
              :class="pac.signed ? 'do' : ''"
              variant="dark"
              >{{ pac.signed ? 'Firmado' : 'Firmar' }}</span
            >
          </div>
        </Column>
      </Layout>
    </div>
    <SignWizzard
      v-else
      @changeView="sign"
      @finished="finished"
      :pac="selectedPac"
      :company="company"
    />
  </div>
</template>

<script>
import ApiClient from '../../../../utils/ApiClient';
import SignWizzard from './SignWizzard.vue';

export default {
  name: 'tabManifest',
  components: { SignWizzard },
  props: {
    company: {
      type: Object,
      required: true,
    },
    signActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pacs: [],
      selectedPac: {},
      model: null,
      isSigning: false,
    };
  },
  mounted() {
    this.finished();
  },
  methods: {
    sign(pac) {
      this.selectedPac = pac;
      this.isSigning = !this.isSigning;
      this.$emit('signing');
    },
    finished() {
      const apiClient = new ApiClient(`Company/Manifest/Pac/${this.company.RFC}`, 'stamp');
      apiClient.Get('?query=a').then((data) => {
        this.pacs = data;
      });
    },
  },
  watch: {
    signActive(valor) {
      this.isSigning = valor;
    },
  },
};
</script>

<style lang="scss" scoped>
.pwCard {
  background-color: #f2f5f8;
  height: 200px;
  font-size: 16px;
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d5dbf3;
  border-radius: 4px;
  font-weight: 600;
  .PwSign {
    color: #4587f9;
    position: absolute;
    bottom: 17px;
    right: 50%;
    padding: 0px;
    transform: translateX(50%);
    line-height: 22px;
    cursor: pointer;
    &.do {
      color: #b3b9bc;
    }
  }
}
</style>
