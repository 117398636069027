<template>
  <div>
    <Frame
      :title="showFrame ? 'Razones sociales' : null"
      :titleAction="showFrame ? titleAction : null"
    >
      <Title title="Datos generales" v-if="showFrame" />
      <Layout>
        <Column size="4">
          <Field label="Código">
            <Input
              :required="true"
              :readonly="readOnly"
              textTransform="uppercase"
              v-model="model.CompanyCode"
              rules="required"
              name="Código"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="RFC">
            <Input
              :required="true"
              :readonly="readOnly"
              textTransform="uppercase"
              v-model="model.RFC"
              name="RFC"
              :rules="{
                required: true,
                regex:
                  /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
              }"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Regimen fiscal">
            <ComboBox
              :required="true"
              :disabled="readOnly"
              name="Regimen fiscal"
              modelEndpoint="company/catalogs"
              api="stamp"
              groupData="regimenFiscalCatalog"
              :limitSearch="null"
              v-model="model.PKC_RegimenFiscal"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="6">
          <Field label="Nombre Persona Moral / Física">
            <Input
              :required="true"
              :readonly="readOnly"
              v-model="model.Name"
              name="Nombre Persona Moral"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="6">
          <Field
            label="Denominación / Razón Social"
            iconAction="information"
            @clickAction="$emit('legalNameInfo')"
          >
            <Input
              :required="true"
              :readonly="readOnly"
              v-model="model.LegalName"
              name="Razón Social"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="CURP">
            <Input
              :readonly="readOnly"
              textTransform="uppercase"
              v-model="model.CURP"
              name="CURP"
              :rules="{
                required: false,
                regex:
                  /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/,
              }"
            />
          </Field>
        </Column>
        <Column size="8" />
        <Column size="12">
          <Field label="Dirección">
            <Input
              :required="true"
              :readonly="readOnly"
              v-model="model.ContactAddress"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="4">
          <Field label="Código postal">
            <ComboBox
              :required="true"
              :disabled="readOnly"
              names="PKC_CodigoPostal"
              modelEndpoint="company/catalogs"
              api="stamp"
              groupData="codigoPostalCatalog"
              v-model="model.PKC_CodigoPostal"
              rules="required"
            />
          </Field>
        </Column>
        <Column size="4" />
        <Column size="4">
          <Field label="Correo electrónico de contacto">
            <Input
              :required="true"
              :readonly="readOnly"
              v-model="model.ContactEmail"
              rules="required|email"
            />
          </Field>
        </Column>
      </Layout>
      <hr />
      <Layout>
        <Column align="right">
          <Button
            color="terciary-outlined"
            @click="readonly && !readOnly ? onCancelEdit() : closeModal()"
          >
            {{ readOnly ? 'Salir' : 'Cancelar' }}
          </Button>
          <Button
            v-if="
              (company && $can('EditCompany', 'STM')) ||
              ($can('AddCompany', 'STM') && !readOnly && !company)
            "
            color="secondary"
            @click="readOnly ? onEdit() : onSave()"
          >
            {{ readOnly ? 'Editar' : company ? 'Actualizar' : 'Guardar' }}
          </Button>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'CompanyForm',
  props: {
    showFrame: {
      type: Boolean,
      default: true,
    },
    company: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAction: 'Agregar',
      model: {
        CURP: null,
        CompanyCode: null,
        // CompanyID: null,
        ContactAddress: null,
        ContactEmail: null,
        Name: null,
        LegalName: null,
        PKC_CodigoPostal: null,
        PKC_RegimenFiscal: null,
        RFC: null,
      },
      readOnly: this.readonly,
    };
  },
  mounted() {
    if (this.readonly) {
      this.$root.$emit('changeModeFrame', 'view');
    }

    if (this.company) {
      if (this.readonly) this.changeTitle('Ver');

      this.model = {
        CURP: this.company.CURP,
        CompanyCode: this.company.CompanyCode,
        CompanyID: this.company.PKCompany,
        ContactAddress: this.company.ContactAddress,
        ContactEmail: this.company.ContactEmail,
        Name: this.company.Name,
        LegalName: this.company.LegalName,
        PKC_CodigoPostal: this.company.PKC_CodigoPostal,
        PKC_RegimenFiscal: this.company.PKC_RegimenFiscal,
        RFC: this.company.RFC,
      };
    }
  },
  methods: {
    closeModal() {
      if (this.showFrame) {
        this.$parent.close();
      } else {
        this.$emit('close');
      }
    },
    async onSave() {
      const validate = await this.isValid();

      if (!validate) {
        return;
      }
      this.save('company', this.model, 'stamp', 'CompanyID')
        .then((data) => {
          if (data) {
            this.closeModal();
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.setNotification(response.data, 'warning');
          } else {
            this.setNotification('Error inesperado, contacte con soporte.', 'warning');
          }
        });
    },
    onEdit() {
      this.readOnly = false;
      this.changeTitle('Editar');

      this.$root.$emit('changeModeFrame', 'edit');
    },
    onCancelEdit() {
      this.readOnly = true;
      this.changeTitle('Ver');

      this.model = JSON.parse(JSON.stringify(this.company));
      this.$root.$emit('changeModeFrame', 'view');
      requestAnimationFrame(() => {
        this.$root.$children[0].$refs.validation.reset();
      });
    },
    changeTitle(title) {
      this.$parent.$parent.$parent.$parent.titleAction = title;
    },
  },
};
</script>
