var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Frame',{attrs:{"title":_vm.showFrame ? 'Razones sociales' : null,"titleAction":_vm.showFrame ? _vm.titleAction : null}},[(_vm.showFrame)?_c('Title',{attrs:{"title":"Datos generales"}}):_vm._e(),_c('Layout',[_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"Código"}},[_c('Input',{attrs:{"required":true,"readonly":_vm.readOnly,"textTransform":"uppercase","rules":"required","name":"Código"},model:{value:(_vm.model.CompanyCode),callback:function ($$v) {_vm.$set(_vm.model, "CompanyCode", $$v)},expression:"model.CompanyCode"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"RFC"}},[_c('Input',{attrs:{"required":true,"readonly":_vm.readOnly,"textTransform":"uppercase","name":"RFC","rules":{
              required: true,
              regex:
                /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
            }},model:{value:(_vm.model.RFC),callback:function ($$v) {_vm.$set(_vm.model, "RFC", $$v)},expression:"model.RFC"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"Regimen fiscal"}},[_c('ComboBox',{attrs:{"required":true,"disabled":_vm.readOnly,"name":"Regimen fiscal","modelEndpoint":"company/catalogs","api":"stamp","groupData":"regimenFiscalCatalog","limitSearch":null,"rules":"required"},model:{value:(_vm.model.PKC_RegimenFiscal),callback:function ($$v) {_vm.$set(_vm.model, "PKC_RegimenFiscal", $$v)},expression:"model.PKC_RegimenFiscal"}})],1)],1),_c('Column',{attrs:{"size":"6"}},[_c('Field',{attrs:{"label":"Nombre Persona Moral / Física"}},[_c('Input',{attrs:{"required":true,"readonly":_vm.readOnly,"name":"Nombre Persona Moral","rules":"required"},model:{value:(_vm.model.Name),callback:function ($$v) {_vm.$set(_vm.model, "Name", $$v)},expression:"model.Name"}})],1)],1),_c('Column',{attrs:{"size":"6"}},[_c('Field',{attrs:{"label":"Denominación / Razón Social","iconAction":"information"},on:{"clickAction":function($event){return _vm.$emit('legalNameInfo')}}},[_c('Input',{attrs:{"required":true,"readonly":_vm.readOnly,"name":"Razón Social","rules":"required"},model:{value:(_vm.model.LegalName),callback:function ($$v) {_vm.$set(_vm.model, "LegalName", $$v)},expression:"model.LegalName"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"CURP"}},[_c('Input',{attrs:{"readonly":_vm.readOnly,"textTransform":"uppercase","name":"CURP","rules":{
              required: false,
              regex:
                /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/,
            }},model:{value:(_vm.model.CURP),callback:function ($$v) {_vm.$set(_vm.model, "CURP", $$v)},expression:"model.CURP"}})],1)],1),_c('Column',{attrs:{"size":"8"}}),_c('Column',{attrs:{"size":"12"}},[_c('Field',{attrs:{"label":"Dirección"}},[_c('Input',{attrs:{"required":true,"readonly":_vm.readOnly,"rules":"required"},model:{value:(_vm.model.ContactAddress),callback:function ($$v) {_vm.$set(_vm.model, "ContactAddress", $$v)},expression:"model.ContactAddress"}})],1)],1),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"Código postal"}},[_c('ComboBox',{attrs:{"required":true,"disabled":_vm.readOnly,"names":"PKC_CodigoPostal","modelEndpoint":"company/catalogs","api":"stamp","groupData":"codigoPostalCatalog","rules":"required"},model:{value:(_vm.model.PKC_CodigoPostal),callback:function ($$v) {_vm.$set(_vm.model, "PKC_CodigoPostal", $$v)},expression:"model.PKC_CodigoPostal"}})],1)],1),_c('Column',{attrs:{"size":"4"}}),_c('Column',{attrs:{"size":"4"}},[_c('Field',{attrs:{"label":"Correo electrónico de contacto"}},[_c('Input',{attrs:{"required":true,"readonly":_vm.readOnly,"rules":"required|email"},model:{value:(_vm.model.ContactEmail),callback:function ($$v) {_vm.$set(_vm.model, "ContactEmail", $$v)},expression:"model.ContactEmail"}})],1)],1)],1),_c('hr'),_c('Layout',[_c('Column',{attrs:{"align":"right"}},[_c('Button',{attrs:{"color":"terciary-outlined"},on:{"click":function($event){_vm.readonly && !_vm.readOnly ? _vm.onCancelEdit() : _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.readOnly ? 'Salir' : 'Cancelar')+" ")]),(
            (_vm.company && _vm.$can('EditCompany', 'STM')) ||
            (_vm.$can('AddCompany', 'STM') && !_vm.readOnly && !_vm.company)
          )?_c('Button',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.readOnly ? _vm.onEdit() : _vm.onSave()}}},[_vm._v(" "+_vm._s(_vm.readOnly ? 'Editar' : _vm.company ? 'Actualizar' : 'Guardar')+" ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }