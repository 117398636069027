<template>
  <div>
    <Frame title="Razones sociales" :titleAction="titleAction">
      <TabStrip>
        <TabStripItem title="Datos Generales">
          <CompanyForm
            :showFrame="false"
            :company="companyToView"
            :readonly="readonly"
            @close="() => $parent.close()"
            @legalNameInfo="$emit('legalNameInfo')"
          />
        </TabStripItem>
        <TabStripItem
          v-if="$can('AddCompany', 'STM') || $can('EditCompany', 'STM')"
          title="Firma de manifiesto"
        >
          <tabManifest :company="companyToView" />
        </TabStripItem>
      </TabStrip>
    </Frame>
  </div>
</template>

<script>
import CompanyForm from './CompanyForm.vue';
import tabManifest from './manifest/tabManifest.vue';

export default {
  name: 'CompanyEdit',
  props: {
    companyToView: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      titleAction: 'Editar',
    };
  },
  components: {
    CompanyForm,
    tabManifest,
  },
};
</script>
