<template>
  <div>
    <h3>PAC: {{ pac.pacLegalName }}</h3>
    <!-- =================== STEP 1: Start =================== -->
    <div v-if="step === 1">
      <h4>Estimado cliente:</h4>
      <div class="text-manifest">
        En cumplimiento de las disposiciones fiscales vigentes para el ejercicio fiscal 2019, es
        necesario que firme el manifiesto de conformidad y autorizacion para la prestación de
        servicio de certificación de comprobantes fiscales digitales por internet, con
        <strong>{{ pac.pacLegalName }}</strong
        >, como proveedor autorizado. asi como el aviso de privacidad, los cuales le pedimos lea con
        detenimiento; una vez firmado, estarán disponibles para que lo descargue. Para ello le
        pedimos, por única ocasión, sigas las instrucciones para su firma y generación.
        <br />
        <br />
        Por su comprensión, Gracias.
      </div>
      <br />
      <CheckBox
        id="checkbox-1"
        v-model="model.step1"
        name="checkbox-1"
        label="Acepto"
        :value="true"
      >
      </CheckBox>
      <br />
    </div>
    <!-- =================== STEP 1: End =================== -->

    <!-- =================== STEP 2: Start =================== -->
    <div v-else-if="step === 2">
      <div @scroll="onScroll" ref="manifestAndPrivacy" class="manifest">
        <h4>MANIFIESTO DE CONFORMIDAD</h4>
        <pre>{{ manifest }}</pre>
        <hr />
        <h4>AVISO DE PRIVACIDAD</h4>
        <pre>{{ PrivacyAdvise }}</pre>
      </div>
      <br />
      <CheckBox
        id="checkbox-1"
        v-model="model.step2"
        :disabled="!enableTick"
        name="checkbox-1"
        :value="true"
        label="He leído y acepto el manifiesto y aviso de privacidad"
      >
      </CheckBox>
      <br />
    </div>
    <!-- =================== STEP 2: End =================== -->

    <!-- =================== STEP 3: Start =================== -->
    <div v-else-if="step === 3">
      <p class="title-sign-with-fiel">
        Para firmar selecciona los archivos CER y KEY de la e.firma (antes FIEL) de
        {{ company.LegalName }}
      </p>
      <Layout>
        <Column size="6">
          <Field label="CER" label-for="input-Cer">
            <Upload
              id="input-Cer"
              v-model="files[0]"
              :required="true"
              :fileExtensions="['cer', 'cert']"
              :showImage="!files[0]"
            >
              <template v-if="files[0]">
                <p class="name--file" :title="files[0].name">
                  {{ files[0].name }}
                </p>
              </template>
            </Upload>
          </Field>
        </Column>
        <Column size="6">
          <Field label="Key" label-for="input-key">
            <Upload
              v-model="files[1]"
              :required="true"
              :fileExtensions="['key']"
              :showImage="!files[1]"
            >
              <template v-if="files[1]">
                <p class="name--file" :title="files[1].name">
                  {{ files[1].name }}
                </p>
              </template>
            </Upload>
          </Field>
        </Column>
      </Layout>
      <Layout>
        <Column md="6">
          <Field label="Contraseña:" label-for="input-password">
            <Input id="input-password" v-model="password" type="password" :required="true" />
          </Field>
        </Column>
      </Layout>
    </div>
    <!-- =================== STEP 3: End =================== -->

    <!-- =================== STEP 4: Start =================== -->

    <div v-else-if="step === 4">
      <Layout>
        <Column md="9">
          <h4>¡El manifiesto ha sido firmado!</h4>
          <div>
            El manifiesto ha sido firmado exitosamente, no olvides descargar y resguardar los
            documentos generados.
          </div>
          <br />
          <a href="#!" @click="downloadContract"
            ><i class="fas fa-file-contract" /> Contrato de servicios</a
          ><br />
          <a href="#!" @click="downloadPrivacy"
            ><i class="fas fa-file-alt" /> Aviso de privacidad</a
          >
        </Column>
      </Layout>
    </div>
    <div v-else-if="step === 5">
      <Layout>
        <Column md="9">
          <h4>El manifiesto NO ha sido firmado</h4>
          <div class="errMsg">{{ ErrMsg }}</div>
        </Column>
      </Layout>
    </div>
    <hr />
    <div class="rightButtons">
      <Button color="terciary-outlined" @click="Cancel">
        {{ cancelExit }}
      </Button>
      <Button :disabled="isNextDisabled" color="secondary" @click="next" v-if="step < 4">
        {{ step === 3 ? 'Firmar' : 'Siguiente' }}
      </Button>
    </div>
  </div>
</template>

<script>
// import ApiClient from './../../../../utils/ApiClient'

export default {
  name: 'SignWizzard',
  props: {
    company: {
      type: Object,
      required: true,
    },
    pac: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        step1: false,
        step2: false,
      },
      manifest: '',
      PrivacyAdvise: '',
      fileCer: '',
      fileKey: '',
      enableTick: false,
      step: 1,
      loading: true,
      password: '',
      files: [null, null],
      ErrMsg: 'Desconocido',
    };
  },
  mounted() {
    this.loading = true;
    this.step = this.pac.signed === true ? 4 : 1;
    if (this.step !== 4) {
      // this.
      const query = `Company/Manifest/Contracts/${this.company.RFC}?LegalName=${this.company.LegalName}&Address=${this.company.ContactAddress}&Email=${this.company.ContactEmail}`;

      this.getAll(query, '', 'stamp').then((data) => {
        const { documents } = data;
        this.manifest = documents.find((x) => x.type === 'Manifest').document;
        this.PrivacyAdvise = documents.find((x) => x.type === 'PrivacyAdvise').document;
        this.loading = false;
      });
    } else {
      this.loading = false;
    }
  },
  methods: {
    Cancel() {
      this.$emit('changeView', this.step);
    },
    downloadContract() {
      const query = `Company/Manifest/DownloadFile/${this.company.RFC}/${this.pac.pkPacSupplier}/manifest`;
      this.$stream(query, '', 'stamp', 'GET').then((response) => {
        this.downloadFile(response, 'manifest');
      });
    },
    downloadPrivacy() {
      const query = `Company/Manifest/DownloadFile/${this.company.RFC}/${this.pac.pkPacSupplier}/privacy`;
      this.$stream(query, '', 'stamp', 'GET').then((response) => {
        this.downloadFile(response, 'privacy');
      });
    },
    downloadFile(infoFile, name) {
      const { data, headers } = infoFile;
      if (headers['content-type'].indexOf('json') === -1) {
        const disposition = headers['content-disposition'];
        let filename = `${name}_`;
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
          if (matches != null && matches[1]) {
            filename += matches[1].replace(/['"]/g, '');
          }
        }
        const blob = new Blob([data], { type: headers['content-type'] });
        const FileDownload = require('js-file-download');
        FileDownload(blob, filename);
      }
    },
    next() {
      if (this.step === 3) {
        this.loading = true;
        const formData = new FormData();
        formData.append('files', this.files[0]);
        formData.append('files', this.files[1]);
        const endpoint = `Company/Manifest/Documents/${this.company.RFC}?FKCompany=${this.pac.pkCompany}&FKPacSupplier=${this.pac.pkPacSupplier}&Password=${this.password}&PacAuthorizationNumber=${this.pac.pacAuthorizationNumber}`;

        this.upload(endpoint, formData, 'stamp')
          .then((...args) => {
            this.loading = false;
            this.step = 5;
            this.ErrMsg =
              ((args[0] || {}).response || {}).data || 'Error desconocido, contacta a soporte';
          })
          .catch(({ response }) => {
            this.setNotification(response.data, 'warning');
          });
      } else {
        this.step++;
        if (this.step === 2) {
          setTimeout(() => {
            if (
              !this.$refs.manifestAndPrivacy.scrollHeight >
              this.$refs.manifestAndPrivacy.clientHeight
            ) {
              this.enableTick = true;
            }
          }, 2000);
        }
      }
    },
    onScroll(evnt) {
      const { target } = evnt;
      if (target.scrollTop + target.clientHeight === target.scrollHeight) {
        this.enableTick = true;
      }
    },
  },
  computed: {
    cancelExit() {
      return this.step < 4 ? 'Cancelar' : 'Salir';
    },
    isNextDisabled() {
      if (this.step === 1) {
        return !this.model.step1;
      }
      if (this.step === 2) {
        return !this.model.step2;
      }
      if (this.step === 3) {
        return !(Boolean(this.files[0]) && Boolean(this.files[1]) && Boolean(this.password));
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.name--file {
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}
.title-sign-with-fiel {
  margin-top: 16px;
  margin-bottom: 8px;
}
.text-manifest {
  line-height: 24px;
  color: #2d3138;
  strong {
    text-transform: uppercase;
  }
}
.rightButtons {
  align-content: right;
  text-align: right;
}
h3 {
  color: #b3b9bc;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 0;
  margin-top: 0;
}
h4 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
}
.errMsg {
  color: #ff0000;
}
.manifest {
  max-height: 32vh;
  overflow-x: hidden;
  overflow-y: auto;
  h4 {
    text-align: center;
  }
}

pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.loading-container {
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  bottom: 0;
  color: #5186ff;
  display: flex;
  font-size: 55px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999999999;
}
</style>
